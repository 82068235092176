<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-experts-list')"
        @delete="helperDeleteItem(`/experts/${$route.params.expertid}`, null, 'apps-experts-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <single-image
            :title="$t('Logo upload')"
            :current-item="currentItem"
            :upload-url="avatarUploadUrl"
            :delete-url="avatarDeleteUrl"
            :image-url.sync="currentItem.avatar.url"
            :card-title="$t('Profile image')"
    >
    </single-image>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
          @submit.stop.prevent="handleSubmit(updateItem)"
          @reset.prevent="resetForm"
      >
        <b-card :title="$t('General informations')">
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider
                  #default="validationContext"
                  :name="$t('First Name')"
                  rules="required"
              >
                <b-form-group :label="$t('First Name')">
                  <b-form-input
                      v-model="currentItem.firstname"
                      id="firstname"
                      :placeholder="$t('First Name')"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('firstname')" class="text-danger"
                  >{{ $t(validation.firstname[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Name')"
                  rules="required"
              >
                <b-form-group :label="$t('Name')">
                  <b-form-input
                      v-model="currentItem.name"
                      id="familytname"
                      :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger"
                  >{{ $t(validation.name[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Company')">
                <b-form-input
                    v-model="currentItem.firm"
                    id="firm"
                    :placeholder="$t('Company')"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Position')">
                <b-form-input
                    v-model="currentItem.position"
                    id="position"
                    placeholder="Position"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-form-group :label="$t('About me')">
              <b-form-textarea
                  id="texterea-aboutme"
                  md="12"
                  rows="3"
                  :placeholder="$t('About me')"
                  v-model="currentItem.aboutme"
              />
            </b-form-group>
          </div>

          <div>
            <hr/>
            <b-form-group :label="$t('Slogan/Motto')">
              <b-form-input
                  v-model="currentItem.slogan"
                  id="slogan"
                  placeholder="slogan/Motto"
              />
            </b-form-group>
          </div>
          <div>
            <b-form-group
                :label="$t('Birthday')"
                label-for="brithday"
            >
              <b-input-group class="mb-1">
                <cleave
                    v-model="currentItem.birthday_text"
                    class="form-control"
                    :raw="false"
                    placeholder="DD.MM.YYYY"
                    :options="{
                  date: true,
                  delimiter: '.',
                  datePattern: ['d', 'm', 'Y'],
                }"
                    v-on:blur="onBirthdayInput"
                />
                <b-input-group-append>
                  <b-form-datepicker
                      button-variant="outline-primary"
                      right
                      size="sm"
                      v-model="currentItem.birthday"
                      right
                      show-decade-nav
                      button-only
                      aria-controls="birthday"
                      locale="de"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      @context="onBirthdayDatePickerChange"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div>
            <b-form-group :label="$t('References')">
              <b-form-textarea
                  id="texterea-references"
                  rows="5"
                  :placeholder="$t('References')"
                  v-model="currentItem.references"
              />
            </b-form-group>
          </div>
          <hr/>
          <div>

            <b-form-group :label="$t('Testimonials')">
              <b-form-textarea
                  id="texterea-testimonials"
                  rows="5"
                  :placeholder="$t('Testimonials')"
                  v-model="currentItem.testimonials"
              />
            </b-form-group>
          </div>
        </b-card>
        <b-card :title="$t('Active from')">
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Start Date')">
                <b-form-datepicker
                    id="startdate"
                    :placeholder="$t('Choose your start Date')"
                    v-model="currentItem.startdate"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('End Date')">
                <b-form-datepicker
                    id="enddate"
                    :placeholder="$t('Choose your End Date')"
                    v-model="currentItem.enddate"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('Contact')">
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Email')"
                  rules="required"
              >
                <b-form-group :label="$t('Email')">
                  <b-form-input
                      v-model="currentItem.email"
                      :name="$t('Email')"
                      id="email"
                      :placeholder="$t('Email')"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('email')" class="text-danger"
                  >{{ $t(validation.email[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Phone')">
                <b-form-input
                    v-model="currentItem.phone"
                    id="phone"
                    :placeholder="$t('Phone')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('Invoice address')">
          <b-form-group :label="$t('Street')" class="mb-1">
            <b-form-input
                v-model="currentItem.invoice_street"
                id="street"
                :placeholder="$t('Street')"
            />
          </b-form-group>

          <b-row>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group
                  :label="$t('Postcode')"
              >
                <b-form-input
                    v-model="currentItem.invoice_postal"
                    id="postcode"
                    :placeholder="$t('Postcode')"
                />
              </b-form-group>
            </b-col>
            <b-col md="9" xl="9">
              <b-form-group :label="$t('City')">
                <b-form-input
                    v-model="currentItem.invoice_city"
                    id="city"
                    :placeholder="$t('City')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('')">
          <b-form-group
              :label="$t('Sections')"
              :description="$t('Please indicate the industries in which you are active or your target customers are')"
          >
            <v-select
                v-model="currentItem.sections"
                label="name"
                :options="sectionsCheckboxOptions"
                multiple
                :close-on-select="false"
            >
            </v-select>
          </b-form-group>

          <b-row>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group :label="$t('Clients')">
                <b-form-checkbox
                    v-for="option in clientCheckboxOptions"
                    :key="option.id"
                    v-model="currentItem.clients"
                    :value="option.id"
                    name="clients"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group :label="$t('Languages')">
                <b-form-checkbox
                    v-for="option in languagesCheckboxOptions"
                    :key="option.id"
                    v-model="currentItem.languages"
                    :value="option.id"
                    name="clients"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group :label="$t('Experttopics')">
                <b-form-checkbox
                    v-for="option in experttopicsCheckboxOptions"
                    :key="option.id"
                    v-model="currentItem.experttopics"
                    :value="option.id"
                    name="experttopics"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group :label="$t('Targetgroups')">
                <b-form-checkbox
                    v-for="option in targetgroupsCheckboxOptions"
                    :key="option.id"
                    v-model="currentItem.targetgroups"
                    :value="option.id"
                    name="targetgroups"
                >
                  {{ option.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('Login')">
          <b-row>
            <!-- password -->
            <b-col cols="6">
              <b-form-group :label="$t('Password')">
                <validation-provider
                    #default="{ errors }"
                    :label="$t('Password')"
                    :name="$t('Password')"
                    vid="Password"
                    rules="min:8|password"
                    class="mt-3"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password')" class="text-danger"
                  >{{ $t(validation.password[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col cols="6">
              <b-form-group :label="$t('Confirm Password')">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Confirm Password')"
                    rules="confirmed:Password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordconfirmFieldType"
                        :placeholder="$t('Confirm Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordconfirmToggleIcon"
                          @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password_confirmation')"
                         class="text-danger"
                  >{{
                      $t(validation.password_confirmation[0])
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button
              variant="success"
              type="submit"
          >
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BInputGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, mounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import SingleImage from '@/views/components/SingleImage'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    BCardActions,
    SingleImage,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  data() {
    return {
      firstname: '',
      currentItem: {
        avatar: {
          url: '',
        },
        id: '',
        name: '',
        email: '',
        firstname: '',
        familyname: '',
        firm: '',
        position: '',
        aboutme: '',
        slogan: '',
        birthday: '',
        birthday_text: '',
        references: '',
        testimonials: '',
        startdate: '',
        enddate: '',
        phone: '',
        invoice_street: '',
        invoice_postal: '',
        invoice_city: '',
        password_confirmation: '',
        password: '',
        sections: [],
        targetgroups: [],
        experttopics: [],
        languages: [],
        clients: [],
      },
      validation: {},
      validate: false,
      loaded: true,
      clientCheckboxOptions: [],
      languagesCheckboxOptions: [],
      sectionsCheckboxOptions: [],
      targetgroupsCheckboxOptions: [],
      experttopicsCheckboxOptions: [],
      avatarUploadUrl: `/experts/avatarupload/${this.$route.params.expertid}`,
      avatarDeleteUrl: `/experts/avatarremove/${this.$route.params.expertid}`,
    }
  },

  methods: {
    onBirthdayDatePickerChange(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
      this.currentItem.birthday_text = moment(ctx.selectedYMD, 'YYYY-MM-DD')
          .format('DD.MM.YYYY')
    },
    onBirthdayInput() {
      let date = moment(this.currentItem.birthday_text, 'DD.MM.YYYY')

      if (date.isValid()) {
        this.currentItem.birthday = date.format('YYYY-MM-DD')
      } else {
        this.currentItem.birthday = null
        this.currentItem.birthday_text = ''
      }
    },
    getItem() {
      this.helperGetItem(`/experts/${this.$route.params.expertid}`)
    },
    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/experts/${this.$route.params.expertid}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    getClients() {
      this.$http
          .get(`/clients`)
          .then((response) => {
            this.clientCheckboxOptions = response.data.items
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getLanguages() {
      this.$http
          .get(`/languages`)
          .then((response) => {
            this.languagesCheckboxOptions = response.data.items
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getTargetgroups() {
      this.$http
          .get(`/targetgroups`)
          .then((response) => {
            this.targetgroupsCheckboxOptions = response.data.items
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getSections() {
      this.$http
          .get(`/sections`)
          .then((response) => {
            this.sectionsCheckboxOptions = response.data.items
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getExperttopics() {
      this.$http
          .get(`/experttopics`)
          .then((response) => {
            this.experttopicsCheckboxOptions = response.data.items
          })
          .catch((error) => {
            console.log(error)
          })
    },

  },

  mounted() {
    this.getItem()
    this.getClients()
    this.getSections()
    this.getLanguages()
    this.getExperttopics()
    this.getTargetgroups()
  },
}
</script>


